import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { getImage } from 'gatsby-plugin-image'
import Posts from '../components/Posts/Posts'
import Avatar from '../assets/images/avatar.png';
import { readingTime } from "reading-time-estimator";
import InfoBox from '../components/InfoBox/InfoBox'
import StickyBox from "react-sticky-box";

import '../scss/pages/post.scss';
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Post({pageContext}) {
    const seo = pageContext.data.seo
    const post = pageContext.data
    const next = pageContext.next
    const previous = pageContext.previous
    const postImage = getImage(post.featuredImage.node.localFile)
    const sidePosts = useStaticQuery(query).allWpPost.edges;

    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={`/poradniki/${post.slug}`} />
            <div className="page-top-section">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="small-header text-center mb-0 text-white">Poradniki</p>
                            <h1 className="header-h1 text-center mt-15 text-white">{post.title}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding post">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <GatsbyImage className="post-image" image={postImage} alt={post.title} title={post.title} />
                            <p className="paragraph top-paragraph black-text-color mt-30 mb-30">{post.poradniki.zajawka}</p>
                            <span className="read-time">{readingTime(post.content).minutes} min.</span>
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }}></div>
                            {post.poradniki.podsumowanie && (
                                <div className="summary mt-30"><p className="summary-title black-text-color">Podsumowanie:</p><div className="sumary-content" dangerouslySetInnerHTML={{ __html: post.poradniki.podsumowanie }}></div></div>
                            )}
                            <div class="author-container mt-30">
                                <div>
                                    {Boolean(post.author.node.avatar.url) ? (
                                        <img className="author-image" src={post.author.node.avatar.url} title="autor" alt="autor" />
                                    ) : (
                                        <img class="author-image" src={Avatar} alt="autor" title="autor" />
                                    )}
                                </div>
                                <div>
                                    <p className="author-name">{post.author.node.firstName + ' ' + post.author.node.lastName}</p>
                                    <p className="author-description">Redaktor portalu UbezpieczenieDoKredytu.pl</p>
                                    <p className="author-paragraph">{post.author.node.description}</p>
                                </div>
                            </div>

                            <div className="articles-link mt-30">
                                {Boolean(previous) ? (<div className="w-50"><a className="previous-post" href={`/poradniki/${previous.slug}`} title={previous.title}>Poprzedni</a></div>) : null}
                                {Boolean(next) ? (<div className="w-50"><a className="next-post" href={`/poradniki/${next.slug}`} title={next.title}>Następny</a></div>) : null}
                            </div>
                        </div>
                        <div className="col-md-4 sidebar">
                            <div className="side-post">
                                <p className="sidebar-title black-text-color mb-25">Polecane artykuły:</p>
                                {sidePosts.map((post, index) => {
                                    const image = getImage(post.node.featuredImage.node.localFile);

                                    return <div className="post-container">
                                                {image && (
                                                    <div className="post-img" style={{ backgroundImage: `url(${image.images.fallback.src})` }} />
                                                )}
                                                <a className="post-link" href={`/poradniki/${post.node.slug}/`} title={post.node.title}>
                                                    {post.node.title}
                                                </a>
                                            </div>
                                })}
                            </div>
                            <StickyBox offsetTop={20} offsetBottom={20} className="sticky-box">
                                <InfoBox />
                            </StickyBox>
                        </div>
                    </div>
                </div>
            </div>
            <Posts />
        </Layout>
    )
}

const query = graphql`
  {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Poradniki"}}}}}
      sort: {fields: date, order: ASC}
      limit: 3
    ) {
      edges {
        node {
          title
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          slug
        }
      }
    }
  }
`